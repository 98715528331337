<script>
import api from '../../../api';
import Layout from "../../layouts/main";
import { required, email, sameAs } from "vuelidate/lib/validators";
import {
  authMethods,
  authFackMethods,
  notificationMethods
} from "@/state/helpers";
// import NumberInputSpinner from "vue-number-input-spinner";
import Swal from 'sweetalert2'
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    // NumberInputSpinner,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('user_info')),
      user: { username: "", email: "", phone: "", nationality: "", education: "" },
      userPassword: {
        password: "",
        confirmPassword: "",
      },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
      infoActive: true,
      securityActive: false,
    };
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    async getUserInfo(){
      const response = (await api.get(`/users/${this.userData.id}`)).data;
      if(response){
        this.userData = response;
        localStorage.setItem('user_info', JSON.stringify(response))
        this.user = { username: this.userData.username, email: this.userData.email, phone: this.userData.phone, nationality: this.userData.nationality, education: this.userData.education }
      }
    },
    async tryToUpdate() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.user.$invalid) {
        return;
      } else {
        try {
          await api.patch(`/users/${this.userData.id}`, {
            "username": this.user.username,
            "email": this.user.email,
            "phone": this.user.phone,
            "nationality": this.user.nationality,
            "education": this.user.education,
          })
          this.getUserInfo();
          this.submitted = false;
          Swal.fire("information Updated!", "information Updated Successfully", "success");
        } catch (e) {
          console.log(e)
        }

      }
    },
    async tryToUpdatePassword(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        try {
          await api.patch(`/users/password/${this.userData.id}`, {
            "password": this.userPassword.password
          })
          this.userPassword = {password: "", confirmPassword: ""};
          this.submitted = false;
          Swal.fire("information Updated!", "information Updated Successfully", "success");
        } catch (e) {
          console.log(e)
        }

      }
    },
    changeTab(val){
      if(val == "information"){
        this.infoActive = true;
        this.securityActive = false;
      }else{
        this.infoActive = false;
        this.securityActive = true;
      }
    }
  },
  watch: {
  },
  computed: {
    score() {
      let result = 0;
      for(let answer of this.answers) {
        if(typeof(answer.value) == 'number') {
          result += answer.value * 5
        } else {
          if(answer.value) {
          result +=  5
          }
        }
      }
      return result
    },
    // userData() {
    //   return JSON.parse(localStorage.getItem('user_info'))
    // },
  },
  mounted() {
    this.getUserInfo();
    this.user= { username: this.userData.username, email: this.userData.email, phone: this.userData.phone, nationality: this.userData.nationality, education: this.userData.education }
  },
  validations() {
    return {
      user: {
        username: { required },
        email: { required, email },
        nationality: { required },
        phone: { required },
        education: { required },
      },
      userPassword: {
        password: { required },
        confirmPassword: {
          sameAsPassword: sameAs( () => this.userPassword.password),
        }
      }
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },

}
</script>

<template>
  <Layout>
      <div class="row">
          <div class="col-12">
            <div class="page-title-box d-flex align-items-center">
              <h4 class="mb-0">Settings</h4>
            </div>
          </div>
      </div>
        <div class="container-fluid bg-white d-flex shadow-sm rounded" style="border: 1px solid rgba(0, 0, 0, 0.125);">
          <!-- <div class="d-flex "  > -->
            <div class="col-3">
              <div class="pt-4 pr-4 w-75">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <a :class="{'nav-link': true, active: this.infoActive}" id="v-pills-home-tab" data-toggle="pill" role="tab" aria-controls="v-pills-home" aria-selected="true" style="cursor: pointer;" @click="changeTab('information')">Information</a>
                  <a :class="{'nav-link': true, active: this.securityActive}" id="v-pills-profile-tab" data-toggle="pill" role="tab" aria-controls="v-pills-profile" aria-selected="false" style="cursor: pointer;" @click="changeTab('security')">Security</a>
                </div>
              </div>
            </div>
            <div class="col-9">
              <div v-if="this.infoActive" class="p-4 w-75">
               <form class="form-horizontal" @submit.prevent="tryToUpdate">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-user-2-line auti-custom-input-icon"></i>
                            <label for="username">Username</label>
                            <input
                              v-model="user.username"
                              type="text"
                              class="form-control"
                              id="username"
                              :class="{ 'is-invalid': submitted && $v.user.username.$error }"
                              placeholder="Enter username"
                            />
                            <div
                              v-if="submitted && !$v.user.username.required"
                              class="invalid-feedback"
                            >Username is required.</div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="useremail">Email</label>
                            <input
                              v-model="user.email"
                              type="email"
                              class="form-control"
                              id="useremail"
                              placeholder="Enter email"
                              :class="{ 'is-invalid': submitted && $v.user.email.$error }"
                            />
                            <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                              <span v-if="!$v.user.email.required">Email is required.</span>
                              <span v-if="!$v.user.email.email">Please enter valid email.</span>
                            </div>
                          </div>
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-phone-line auti-custom-input-icon"></i>
                            <label for="useremail">Phone</label>
                            <input
                              v-model="user.phone"
                              type="phone"
                              class="form-control"
                              id="userphone"
                              placeholder="Enter phone"
                              :class="{ 'is-invalid': submitted && $v.user.phone.$error }"
                            />
                            <div v-if="submitted && $v.user.phone.$error" class="invalid-feedback">
                              <span v-if="!$v.user.phone.required">Phone is required.</span>
                            </div>
                          </div>
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-globe-line auti-custom-input-icon"></i>
                            <label for="nationality">Nationality</label>
                            <input
                              v-model="user.nationality"
                              type="text"
                              class="form-control"
                              id="nationality"
                              placeholder="Enter Nationality"
                              :class="{ 'is-invalid': submitted && $v.user.nationality.$error }"
                            />
                            <div v-if="submitted && $v.user.nationality.$error" class="invalid-feedback">
                              <span v-if="!$v.user.nationality.required">Nationality is required.</span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-book-line auti-custom-input-icon"></i>
                            <label for="education">Education</label>
                            <input
                              v-model="user.education"
                              type="text"
                              class="form-control"
                              id="education"
                              placeholder="Enter education"
                              :class="{ 'is-invalid': submitted && $v.user.education.$error }"
                            />
                            <div v-if="submitted && $v.user.education.$error" class="invalid-feedback">
                              <span v-if="!$v.user.education.required">Education is required.</span>
                            </div>
                          </div>

                          <div class="text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >Update Information</button>
                          </div>
                </form>
              </div>
              <div v-if="this.securityActive" class="p-4 w-75">
               <form class="form-horizontal" @submit.prevent="tryToUpdatePassword">
                  <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">New Password</label>
                            <input
                              v-model="userPassword.password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              :class="{ 'is-invalid': submitted && $v.userPassword.password.$error }"
                            />
                            <div
                              v-if="submitted && !$v.userPassword.password.required"
                              class="invalid-feedback"
                            >
                              Password is required.
                            </div>
                  </div>
                    
                  <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="confirmuserpassword">Confirm Password</label>
                            <input
                              v-model="userPassword.confirmPassword"
                              type="password"
                              class="form-control"
                              id="confirmuserpassword"
                              placeholder="Enter password"
                              :class="{ 'is-invalid': submitted && $v.userPassword.confirmPassword.$error }"
                            />
                            <div
                              v-if="submitted && $v.userPassword.confirmPassword.$error"
                              class="invalid-feedback"
                            >
                              Confirm password mismatch.
                            </div>
                  </div>

                  <div class="text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >Update Password</button>
                  </div>
                </form>
              </div>
            </div>
          <!-- </div> -->
        </div>
  </Layout>
</template>